import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/_services';
import { authConfig } from '@app/sso-config';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-authorised',
  templateUrl: './authorised.component.html',
  styleUrls: ['./authorised.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorisedComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: Router,
  ) { 
    
  }

  ngOnInit(): void {
    
  }

}
