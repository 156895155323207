import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/_services';
import { Account } from '@app/_models/account';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';
const baseUrl = `${environment.apiURL}`;

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  account: string='';

  serviceBase: string = baseUrl;
  duongdan_file:string = '/Uploads/HDSD_QLTT.pdf';
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log(this.duongdan_file);
    console.log(this.serviceBase);
   this.account= localStorage.getItem('UserName') ? localStorage.getItem('UserName') : sessionStorage.getItem('UserName') || '';
  }

  logout() {
    this.authService.logout();
  }

  Change_pass(){
    this.router.navigate(['/change-pass']);
  }
}
