import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  hideSideNav: boolean = false;

  constructor(private http: HttpClient) { }

  toggleSideNav(): void {
    this.hideSideNav = !this.hideSideNav;
  }
}
