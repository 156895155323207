<div class="modal-header">
    <h4 class="modal-title">
      <img src="assets/images/ico_info.png" alt="ico"> {{title}}
    </h4>
</div>
<div class="modal-body text-center">
  <p class="confirm-msg">{{prompt}}</p>
</div>
<div class="modal-footer danger">
    <button type="button" class="btn btn-sm btn-danger" (click)="confirm()">{{okText}}</button>
    <button type="button" class="btn btn-sm btn-default" (click)="decline()">{{cancelText}}</button>
</div>
