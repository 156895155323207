import { Component, ViewEncapsulation } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './sso-config';
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
  ]
})
export class AppComponent {
  constructor(
    private ssoService: OAuthService
  ) { 
    this.ssoService.configure(authConfig);
    this.ssoService.tokenValidationHandler = new JwksValidationHandler();
    this.ssoService.loadDiscoveryDocumentAndTryLogin();
    this.ssoService.setupAutomaticSilentRefresh();

    this.ssoService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'token_received':
          break;
        case 'token_refreshed':
          break;
      }
    });
  }
  title = 'Angular11';
}
