<div class="be-left-sidebar bg-white">
  <div class="nav-side-menu">
    <div class="menu-list">
      <ul id="menu-content" class="menu-content">
        <li [routerLinkActive]="['active']"><a [routerLink]="['dashboard']"><span
              class="icon icon-trangchu"></span>Trang chủ</a></li>
        <li class="divider"></li>
        <li data-toggle="collapse">
          <a href="javascript:void(0);"><span class="icon icon-xuphat"></span>Trang trại<span class="arrow"></span></a>
          <ul class="sub-menu collapse">
            <li [routerLinkActive]="['active']">
              <a [routerLink]="['/trangtrai/hoso-trangtrai']">Thông tin trang trại</a>
            </li>
            <li [routerLinkActive]="['active']">
              <a [routerLink]="['/trangtrai/bandoquyhoach-trangtrai']">Upload hồ sơ quy hoạch trang trại</a>
            </li>
            <li [routerLinkActive]="['active']">
              <a [routerLink]="['/trangtrai/khoa-mo-hstrangtrai']">Khóa/Mở điều chỉnh thông tin trang trại</a>
            </li>
            <li>
              <a [routerLink]="['/trangtrai/bando-trangtrai']">Bản đồ hiện trạng trang trại</a>
            </li>
            <li>
              <a [routerLink]="['/trangtrai/quyhoach-trangtrai']">Bản đồ quy hoạch trang trại</a>
            </li>
          </ul>
        </li>
        <li class="divider"></li>
        <li data-toggle="collapse">
          <a href="javascript:void(0);"><span class="icon icon-baocao"></span>Báo cáo<span class="arrow"></span></a>
          <ul class="sub-menu collapse">
            <li [routerLinkActive]="['active']"><a [routerLink]="['/baocao/baocao-05']">Mẫu 05 - Sổ theo dõi phát
                triển kinh tế trang trại</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/baocao/baocao-09']">Mẫu 09 - Báo cáo số lượng
                trang trại (quý)</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/baocao/baocao-10']">Mẫu 10 - Báo cáo số lượng
                trang trại (năm)</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/baocao/xaydung-trangtrai']">Số liệu tổng hợp về hiện
                trạng xây dựng trang trại
              </a></li>
          </ul>
        </li>
        <li class="divider"></li>
        <li data-toggle="collapse">
          <a href="javascript:void(0);"><span class="icon icon-hethong"></span>Hệ thống <span class="arrow"></span></a>
          <ul class="sub-menu collapse">
            <li [routerLinkActive]="['active']" *ngIf="isadmin=='1'" ><a [routerLink]="['/he-thong/nhanvien']">Nhân viên</a>
            </li>
            <li [routerLinkActive]="['active']" *ngIf="isadmin=='1'"><a [routerLink]="['/he-thong/nguoi-dung']">Người
                dùng</a></li>
            <li [routerLinkActive]="['active']" *ngIf="isadmin=='1'"><a [routerLink]="['/he-thong/nhom-nd']">Nhóm người
                dùng</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/he-thong/quyen']">Quyền</a>
            </li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/he-thong/config-ht']">Cấu hình hệ thống</a>
            </li>
          </ul>
        </li>
        <li class="divider"></li>
        <li data-toggle="collapse">
          <a href="javascript:void(0);"><span class="icon icon-danhmuc"></span>Danh mục <span class="arrow"></span></a>
          <ul class="sub-menu collapse">
            <li class="font-600 caption">Hành chính</li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/dia-phuong']">Tỉnh thành</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/chuc-vu']">Chức vụ</a></li>
            <li class="font-600 caption">Nghiệp vụ</li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/don-vi']">Đơn vị</a>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/loai-trangtrai']">Loại trang trại</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/loai-sanpham']">Loại sản phẩm</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/loai-congtrinh']">Loại công trình</a></li>
            <li [routerLinkActive]="['active']"><a [routerLink]="['/danh-muc/loai-hoso']">Loại hồ sơ</a></li>
          </ul>
      </ul>
    </div>
  </div>
</div>