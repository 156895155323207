<header class="fixed-top bg-white">
  <div class="container-fluid">
    <div id="application-name" title="">
      <a [routerLink]="['/dashboard']">
        <h1 class="font-opensans text-uppercase">Hệ thống cơ sở dữ liệu thông tin quản lý trang trại</h1>
      </a>
    </div>
    <div id="account-info" class="pull-right text-right">
      <span dropdown>
        <a title="Trợ giúp" class="nav-icon hidden-xs" href="#">
          <img src="./assets/images/icon_help.png" alt="Help" />
        </a>
        <a id="dd-append-to-body" dropdownToggle (click)="false" aria-controls="dd-append-to-body-dropdown" style="cursor:pointer;">
          Tài khoản <b>{{ account}} </b>
          &nbsp;
          <img class="avatar" src="./assets/images/icon_account2.png" alt="Avatar">
        </a>
        <ul id="dd-append-to-body-dropdown" *dropdownMenu class="dropdown-menu" class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dd-append-to-body">
          <!-- <li role="menuitem">
            <a class="dropdown-item" href="javascript:void()" (click)="Change_pass()">Đổi mật khẩu</a>
          </li> -->
          <li role="menuitem">
            <a class="dropdown-item" href="{{serviceBase+duongdan_file}}" target="_blank">Tải hướng dẫn sử dụng</a>
          </li>
          <li role="menuitem" class="dropdown-divider divider"></li>
          <!-- <li role="menuitem">
            <a class="dropdown-item">Log đăng nhập</a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item">Log hệ thống</a>
          </li>
          <li role="menuitem" class="dropdown-divider divider"></li> -->
          <li role="menuitem"><a href="#" class="dropdown-item" (click)="logout()"><b>Thoát</b></a></li>
        </ul>
      </span>
    </div>
  </div>
</header>
