<section class="clearfix">
  <app-top-nav></app-top-nav>
    <app-side-nav></app-side-nav>
    <div class="be-content">
      <div class="main-content container-fluid">
        <section class="clearfix">
          <router-outlet></router-outlet>
        </section>
      </div>
      <footer class="clearfix">
        <div id="vnpt-logo"></div>
        <p class="gray-color">
          <small>
            &copy; 2023 bản quyền thuộc VNPT Thừa Thiên Huế<br/>
            Địa chỉ: 51c Hai Bà Trưng, P.Vĩnh Ninh, TP Huế, Thừa Thiên Huế - Điện thoại: (0234) 3846949            
          </small>
        </p>
    </footer>
    </div>
</section>
