import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://sso.huecity.vn/auth/realms/hues',
    clientId: 'vnpt-pccc', // The "Auth Code + PKCE" client
    responseType: 'code',
    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://qltt.vnpthue.com.vn',
    scope: 'openid', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 86400, 
    requireHttps: false,
    dummyClientSecret: 'OE5rG8He6255TjcwqgmagAzD1Rac71ol',
    showDebugInformation: false,
    clearHashAfterLogin: false,
    sessionChecksEnabled: false,
    nonceStateSeparator: 'semicolon',
};