import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidenavService } from '@app/_services';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser'

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideNavComponent implements OnInit {

  menuState:string = 'out';

  isadmin :string='0';
  config_nd :string='0';
  config_qt :string='0';
  constructor(
    public sideNavService: SidenavService
  ) { }

  ngOnInit(): void {    
    this.isadmin = localStorage.getItem('IsAdmin') ? localStorage.getItem('IsAdmin') : sessionStorage.getItem('IsAdmin') || '0';
  }

  ngAfterViewInit(){
      $(document).ready(function(){
        $('li[data-toggle="collapse"]>a').click( function(){
          $('li[data-toggle="collapse"]>a').not(this).removeClass('collapsed');
            $(this).toggleClass("collapsed");
            var target = $(this).parent().children('.sub-menu');
            $('ul.sub-menu').not(target).addClass("collapse");
            $(target).toggleClass("collapse");          
        });
      });
  }

  toggleMenu() {   
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
}
