import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuestComponent {
  constructor(
  ) { 
  }
}
